// MainLayout.tsx
import React, { FC, useState, useEffect, useContext, useCallback } from "react";
import { styled } from '@mui/material/styles';
import { Drawer, IconButton } from '@mui/material';
import { LocationOn as LocationOnIcon } from "@mui/icons-material";
import { HomeModelsContext } from "@contexts";
import { LeftPanel } from "../LeftPanel";
import { RightPanel } from "../RightPanel";
import { Footer } from "@components";
import { Outlet } from 'react-router-dom'; // Importar Outlet

const LayoutContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
}));

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
}));

const SidebarLeft = styled('div')(({ theme }) => ({
  width: 240,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const SidebarRight = styled('div')(({ theme }) => ({
  width: 240,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const MainContent = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  overflow: 'auto',
}));

const StyledFooter = styled('footer')(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  textAlign: 'center',
}));

export type MainLayoutProps = {
  children?: React.ReactNode | React.ReactNode[];
};

const MainLayout: FC<MainLayoutProps> = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { homeModels } = useContext(HomeModelsContext);

  // Memoizar handleMobileMenuClose para asegurar estabilidad
  const handleMobileMenuClose = useCallback(() => {
    setIsMobileMenuOpen(false);
  }, []);

  // Memoizar handleMobileMenuOpen (opcional pero recomendado)
  const handleMobileMenuOpen = useCallback(() => {
    setIsMobileMenuOpen(true);
  }, []);

  useEffect(() => {
    if (isMobileMenuOpen) {
      handleMobileMenuClose();
    }
  }, [homeModels, isMobileMenuOpen, handleMobileMenuClose]);

  return (
    <LayoutContainer>
      <ContentWrapper>
        <SidebarLeft>
          <LeftPanel onCloseMobileMenu={handleMobileMenuClose} />
        </SidebarLeft>

        <MainContent>
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={handleMobileMenuOpen}
            sx={{ display: { md: 'none' }, mb: 2 }}
          >
            <LocationOnIcon />
          </IconButton>

          <Drawer
            anchor="left"
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
          >
            <LeftPanel onCloseMobileMenu={handleMobileMenuClose} />
          </Drawer>
          <Outlet />
        </MainContent>

        <SidebarRight>
          <RightPanel />
        </SidebarRight>
      </ContentWrapper>

      <StyledFooter>
        <Footer />
      </StyledFooter>
    </LayoutContainer>
  );
};

export { MainLayout };
