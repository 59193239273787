import { makeStyles } from '@mui/styles';
import { COLORS } from "@constants";

const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: "sans-serif",
    padding: '2rem',
  },
  cardTitle: {
    color: COLORS.tableText,
    fontSize: "1.5rem",
    fontWeight: "bold",
    width: "100%",
  },
  contactLabels: {
    fontSize: "1rem",
    fontFamily: "Roboto,sans-serif",
    fontWeight: 400,
    color: "black",
    '& div': {
      paddingTop: '1rem',
    }
  },
  contactValues: {
    fontSize: "1rem",
    fontFamily: "Roboto,sans-serif",
    color: "black",
    fontWeight: "bold",
    '& div': {
      paddingTop: '1rem',
    }
  },
  _showMoreButton: {
    padding: "6px 16px",
    borderRadius: 0,
    border: "2px solid #000",
    backgroundColor: "white",
    color: "black",
    transition: "background 1s, color 1s",
    "&:hover": {
      backgroundColor: "#000",
      color: "#fff"
    }
  },
  showMoreButton: {
    backgroundColor: "transparent",
    border: `3px solid ${COLORS.main}`,
    borderRadius: "50px",
    //-webkit-transition: all .15s ease-in-out,
    transition: "all .15s ease-in-out",
    color: COLORS.main,
    '&:hover': {
      boxShadow: `0 0 10px 0 ${COLORS.main} inset, 0 0 20px 2px ${COLORS.main}`,
      border: `3px solid ${COLORS.main}`,	
    }
  },
}));

export { useStyles };
