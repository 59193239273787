// useApi.ts
import { useState, useEffect, Dispatch, SetStateAction, useMemo, useCallback } from 'react'
import { AxiosRequestConfig } from 'axios'

import { api } from '@utils'
import { ApiError } from './../../errors'
import { ErrorTypes } from './../../types'

import { UseApiError, UseApiResponse, UseApiConfig } from './types'

function useApi<RES, DATA>(
  getConfig: () => AxiosRequestConfig,
  responseGetter: (response: RES) => DATA,
  config: UseApiConfig<DATA>,
): [UseApiResponse<DATA>, () => void, Dispatch<SetStateAction<DATA>>] {
  const [data, setData] = useState<DATA>(config.baseData)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<UseApiError | undefined>(undefined)

  // Memoizar getConfig
  const requestConfig = useMemo(() => getConfig(), [getConfig])

  // Desestructurar 'onError' de 'config'
  const { onError } = config;

  const fetch = useCallback(async () => {
    setIsLoading(true)
    try {
      const { data: fetchedData } = await api.request<RES>(requestConfig)
      setData(responseGetter(fetchedData))
    } catch (err: any) { // Tipar correctamente 'err'
      if (err.response) {
        console.error(err.response)
        const newError = new ApiError(
          err.response.data.error.message,
          err.response.status,
          err.response.data.error.type,
        )
        setError(newError)
        if (onError) onError(newError)
      } else {
        const errMessage = `Bad request or server didn't respond`
        console.error(errMessage)
        const newError = new ApiError(errMessage, 400, ErrorTypes.RequestError)
        setError(newError)
        if (onError) onError(newError)
      }
    } finally {
      setIsLoading(false)
    }
  }, [requestConfig, onError, responseGetter])

  useEffect(() => {
    fetch()
  }, [fetch])

  return [{ data, isLoading, error }, fetch, setData]
}

function useApiCall<REQ_DATA, RES>(
  apiCall: (data: REQ_DATA) => AxiosRequestConfig,
): [(data: REQ_DATA) => Promise<RES>, boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetch = useCallback(
    async (fetchData: REQ_DATA) => {
      setIsLoading(true)
      try {
        const { data: fetchedData } = await api.request<RES>(apiCall(fetchData))
        return fetchedData
      } catch (err: any) { // Tipar correctamente 'err'
        if (err.response) {
          console.error(err.response)
          throw new ApiError(
            err.response.data.error.message,
            err.response.status,
            err.response.data.error.type,
            err.response.data.error.validation?.keys,
          )
        } else {
          const errMessage = `Bad request or server didn't respond`
          console.error(errMessage)
          throw new ApiError(errMessage, 400, ErrorTypes.RequestError)
        }
      } finally {
        setIsLoading(false)
      }
    },
    [apiCall],
  )

  return [fetch, isLoading]
}

export { useApi, useApiCall }
