import React from "react";
import { Box } from "@mui/material";
import { HomeCard } from "@components";
import { useStyles } from "./styles";

const Home: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.middlePanel}>
      <HomeCard/>
    </Box>
  );
};

export { Home };
