// App.tsx
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { BackdropProvider, HomeModelsProvider } from "@contexts";
import { AdminLayout, Home, MainLayout } from "@components";
import { Details } from "./Details";
import { AdminDashboard } from "./AdminDashboard";
import { Services } from "./Services";
import { Memberships } from "./Memberships";
import DisclaimerPopup from "./Modals/MainDisclaimer";
import { NotFound } from "@components";

function App() {
  const [popupAccepted, setPopupAccepted] = useState(false);

  useEffect(() => {
    const storedPopupAccepted = localStorage.getItem('popupAccepted');
    setPopupAccepted(Boolean(storedPopupAccepted));
  }, []);

  return (
    <BackdropProvider>
      {!popupAccepted && (
        <DisclaimerPopup />
      )}
      <HomeModelsProvider>
        <Router>
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Home />} />
              <Route path="test" element={<Home />} />
              <Route path="show/:id" element={<Details />} />
            </Route>

            <Route path="/admin" element={<AdminLayout />}>
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="services" element={<Services />} />
              <Route path="memberships" element={<Memberships />} />
              <Route path="user" element={<Services />} />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </HomeModelsProvider>
    </BackdropProvider>
  );
}

export default App;
