import React, { FC, ReactNode, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography
} from "@mui/material";
import Dialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import { useStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";

interface DialogProps extends MuiDialogProps {
  children?: ReactNode;
}

const DisclaimerPopup: FC = () => {
  const classes = useStyles();
  const [showPopup, setShowPopup] = useState(true);
  const [scroll] = useState<DialogProps['scroll']>('paper');
  const [maxWidth] = React.useState<DialogProps['maxWidth']>('md');

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleAccept = () => {
    localStorage.setItem('popupAccepted', 'true');
    handleClosePopup();
  };

  const handleReject = () => {
    window.location.href = 'https://www.google.com'; // Redirigir a Google
  };

  return (
    <Box className={showPopup ? classes.mainDialog : classes.hidden}>
      <Dialog
        scroll={scroll}
        maxWidth={maxWidth}
        open={showPopup}
        aria-labelledby="disclaimer-popup"
      >
        <Box className={classes.header}>Best Euro Girls</Box>
        {/* <Box className={classes.mainContent}> */}
          <Grid container spacing={2} className={classes.mainContent}>
            <Box
              className={classes.closeButtonMobile}
              onClick={() => handleReject()}
            >
              <CloseIcon fontSize="large" />
            </Box>
            <Grid item xs={12} sm={9}>
              <Box className={classes.leftSection}>
                <Box className={classes.leftSectionOverlay} />
                <Typography className={classes.subtitle}>
                  BestEuroGirls, including all webpages, links and images , displays sexually explicit material. Only consenting adults are authorized beyond this page. If you are a minor (under the age of 18 years or 21 years where 18 isn't the legal age of majority), if sexually explicit material offends you or if it's illegal to view such material in your community, you MUST leave this site by clicking "LEAVE" below. By clicking "ENTER", you agree to the statement at the bottom of this page.
                </Typography>
                <Typography className={classes.subtitle}>
                  Best Euro Girls has a zero tolerance policy for child pornography or minors advertising or utilizing our site. I agree to report any illegal services or activities which violate Terms of Use. I also agree to report suspected exploitation of minors and/or human trafficking to the appropriate authorities.
                </Typography>
                <div className={classes.popupTitle}>
                  BY ENTERING BESTEUROGIRLS.COM YOU AGREE THAT:
                </div>
                <Typography component="ul" className={`${classes.subtitle} ${classes.bulletList}`}>
                  <li>you are an adult in your community and are at least 18 years old (21 in some countries)</li>
                  <li>you agree with terms and conditions</li>
                  <li>you agree that sexually explicit material is not deemed to be obscene or illegal in your community</li>
                  <li>you accept full responsibility for your own actions</li>
                  <li>you agree to the use of cookies</li>
                </Typography>
                <div className={classes.popupTitle}>
                  YOU HAVE READ THIS AGREEMENT, UNDERSTOOD IT AND AGREE TO BE BOUND BY IT.
                </div>
                <div className={classes.footerContainer}>
                  <div className={classes.buttonBar}>
                    <Button onClick={handleAccept} variant="contained" className={`${classes.button} ${classes.redButton}`}>I Agree</Button>
                    <Button onClick={handleReject} variant="contained" className={classes.button}>Cancel</Button>
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={3} className={classes.rightSection}>
            </Grid>
          </Grid>
        {/* </Box> */}
      </Dialog>
    </Box>
  );
}

export default DisclaimerPopup;