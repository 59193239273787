import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
} from "react";
import { useLocalStorage } from "@hooks";

type ContextType = {
  token: string;
  setToken: Dispatch<SetStateAction<string>>;
};

type ContainerProps = {
  children: React.ReactNode; //👈 children prop typr
};

const GlobalContext = createContext<ContextType>({} as ContextType);

const GlobalProvider: FC<ContainerProps> = (props: ContainerProps) => {
  const [token, setToken] = useLocalStorage("token", "");

  return (
    <GlobalContext.Provider value={{ token, setToken }}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export { GlobalProvider, GlobalContext };
