import { makeStyles } from '@mui/styles';
import { COLORS } from "@constants";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hidden: {
    display: 'none',
  },
  mainDialog: {
    borderRadius: '1rem',
    // minWidth: "60%",
    '@media (max-width: 960px)': {
      width: "100%",
      // padding: '2rem',
      top: "0",
      left: "0",
      bottom: "0",
      right: "0",
      overflowY: "auto",
    },
  },
  mainContent: {
    backgroundColor: 'white',
    width: "100%",
    // padding: '2rem',
    '@media (max-width: 960px)': {
      padding: '1rem',
      top: "0",
      left: "0",
      bottom: "0",
      right: "0",
    },
  },  
  header: {
    height: '50px', // Cambiar "heigth" a "height"
    padding: '2rem',
    backgroundColor: COLORS.primary,
    color: 'black',
    fontFamily: "fantasy",
    fontSize: "2rem",
  },
  leftSection: {
    padding: '4rem',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",    
    backgroundRepeat: "no-repeat",
    position: "relative",
    '@media (max-width: 960px)': {
      padding: '0rem',
      top: "0",
      left: "0",
      bottom: "0",
      right: "0",
    },
  },
  closeButtonMobile: {
    position: "absolute",
    top: 0,
    right: 0,
    margin: 7,
    backgroundColor: COLORS.primary,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "15%",
    zIndex: 1000,
    "&:hover": {
      backgroundColor: "#FC5C65",
    },
    '@media (max-width: 960px)': {
      display: "none",
    },
  },
  leftSectionOverlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  subtitle: {
    margin: '2rem',
    textAlign: "left",
    color: "#000",
    zIndex: 99,
    fontSize: ".8rem !important",
  },
  popupTitle: {
    width: "100%",
    margin: '2rem',
    fontWeight: "bold",
    textAlign: "left",
    color: COLORS.title,
    zIndex: 99,
  },
  bulletList: {
    width: "100%"
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonBar: {
    display: 'flex',
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  button: {
    padding: `'0rem' !important`,
    margin: `'3rem' !important`,
    backgroundColor: '#e9e9e9 !important',
    border: 'none',
    borderRadius: '5px !important',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#d4d4d4',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  redButton: {
    padding: `'2rem' !important`,
    margin: `'1rem' !important`,
    backgroundColor: 'red !important',
    color: 'white',
    fontSize: '1.2rem !important',
  },
  rightSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 60,
    color: "white",
    position: "relative",
    backgroundImage: `url(https://www.eurogirlsescort.com/dist/images/over-18.png)`,
    backgroundSize: "cover",
    '@media (max-width: 960px)': {
      display: "none",
    },
  },
}));

export { useStyles };

  