// CountriesMenu.tsx
import React, { useEffect } from 'react';
import { useStyles } from "./styles";

import List from '@mui/material/List';
import { Box } from "@mui/material";

import CountryMenuItem from './countryMenuItem';
import { Country, GetCountriesResponse } from './types';
import { getCountries } from './api';
import { useApi } from '@hooks';

const CountriesMenu: React.FC = () => {
  const classes = useStyles();

  const getCountriesResponseGetter = (responseData: GetCountriesResponse) => responseData?.countries ?? [];
  const [{ data: countries }, fetchCountries] = useApi<GetCountriesResponse, Country[]>(getCountries, getCountriesResponseGetter, {
    baseData: [],
    //onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE),
    onError: () => { console.log('error') },
  });

  useEffect(() => {
    if (countries.length === 0) {
      fetchCountries();
    }
  }, [countries.length, fetchCountries]); // Incluir las dependencias aquí

  return (
    <Box className={classes.countryList}>
      <h2 className={classes.continent}>Europe</h2>
      <List component="nav" className={classes.appMenu} disablePadding>
        {countries.map((item, index) => (
          <CountryMenuItem {...item} key={index}/>
        ))}
      </List>
    </Box>
  );
};

export default CountriesMenu;
