// Footer.tsx
import * as React from "react";
import { Container, Typography, Link, Box } from "@mui/material";

const Footer: React.FC = () => {
  return (
    <Box component="footer" sx={{ backgroundColor: 'background.paper', py: 6 }}>
      <Container maxWidth="lg">
        {/* Sección "Contact Us" */}
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="right">
            <Box component="span" sx={{ fontWeight: 'bold', mr: 1 }}>
              Contact Us:
            </Box>
            <Link
              href="mailto:contact@besteurogirls.com"
              color="inherit"
              sx={{ textDecoration: 'none' }}
            >
              contact@besteurogirls.com
            </Link>
          </Typography>
        </Box>

        {/* Sección de Copyright */}
        <Box>
          <Typography variant="body2" color="text.secondary" align="right">
            © {new Date().getFullYear()}{" "}
            <Link
              color="inherit"
              href="https://your-website.com/"
              sx={{ textDecoration: 'none' }}
            >
              BestEuroGirls.com
            </Link>
            . All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export { Footer };
