import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import { Model } from "src/components/Home/types";

type ContextType = {
  homeModels: Model[];
  setHomeModels: Dispatch<SetStateAction<Model[]>>;
  showModelsDisclaimer: boolean;
  setShowModelsDisclaimer: Dispatch<SetStateAction<boolean>>;
};

type ContainerProps = {
  children: React.ReactNode; //👈 children prop typr
};

const HomeModelsContext = createContext<ContextType>({} as ContextType);

const HomeModelsProvider: React.FC<ContainerProps> = (props: ContainerProps) => {
  const [homeModels, setHomeModels] = useState<Model[]>([]);
  const [showModelsDisclaimer, setShowModelsDisclaimer] = useState<boolean>(false);
  
  return (
    <HomeModelsContext.Provider value={{ homeModels, setHomeModels, showModelsDisclaimer, setShowModelsDisclaimer }}>
      {props.children}
    </HomeModelsContext.Provider>
  );
};

export { HomeModelsProvider, HomeModelsContext };
