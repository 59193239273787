// import { makeStyles } from '@mui/material/styles'
import { makeStyles } from '@mui/styles';

const drawerWidth = 240

const useStyles = makeStyles(() => ({
  appMenu: {
    width: '100%',
    fontSize: "12px",
    listStyleType: "none",
    margin: 0,
    padding: 0,
    borderBottom: "1px solid #ddd",
  },
  navList: {
    width: drawerWidth,
  },
  menuItem: {
    width: drawerWidth,
  },
  menuItemIcon: {
    color: '#97c05c',
  },
  countryList: {
    paddingTop: 10,
  },
  continent: {
    fontSize: "14px",
    color: "#cb2845",
    fontWeight: 900,
    margin: "0 10px 10px",
    padding: 0,
    textTransform: "uppercase",
  }
}));

export { useStyles };
