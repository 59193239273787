import { ErrorTypes } from '@types'

class ApiError extends Error {
  status: number

  type: ErrorTypes

  validationKeys?: string[]

  constructor(message: string, status: number, type: ErrorTypes, validationKeys?: string[]) {
    super(message)
    this.status = status
    this.type = type
    this.validationKeys = validationKeys
  }
}

export { ApiError }
