import React from 'react';
import { Box, Typography } from '@mui/material';

const NotFound: React.FC = () => (
  <Box sx={{ textAlign: 'center', mt: 5 }}>
    <Typography variant="h4">404 - Página No Encontrada</Typography>
    <Typography variant="body1">Lo siento, la página que buscas no existe.</Typography>
  </Box>
);

export {NotFound};
