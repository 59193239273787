// useGetModelsService.ts
import { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackdropContext, HomeModelsContext } from '@contexts';
import { useApiCall } from '@hooks';
import { shuffle } from 'lodash';
import { GetModelsPayload, GetModelsResponse, Model } from 'src/features/Models/types';
import { getModels } from './api';

export const useGetModelsService = () => {
  const navigate = useNavigate();
  const {
    homeModels,
    setHomeModels,
    showModelsDisclaimer,
    setShowModelsDisclaimer,
  } = useContext(HomeModelsContext);
  const { setBackdropIsOpen } = useContext(BackdropContext);

  const [getModelsApi, isLoading] = useApiCall<GetModelsPayload, GetModelsResponse>(
    getModels,
  );

  useEffect(() => {
    setBackdropIsOpen(isLoading);
  }, [isLoading, setBackdropIsOpen]);

  const checkRootPath = useCallback(() => {
    if (
      window.location.pathname !== '/' &&
      !window.location.pathname.startsWith('/admin')
    ) {
      navigate('/');
    }
  }, [navigate]);

  const handleSetHomeModels = useCallback(
    (models: Model[]) => {
      const vipModels = models.filter(model => model.membership === 'VIP');
      const topModels = models.filter(model => model.membership === 'TOP');
      const verifiedTrueModels = models.filter(
        model => model.membership === null && model.verified === true,
      );
      const verifiedFalseModels = models.filter(
        model => model.membership === null && model.verified === false,
      );

      const shuffledVipModels = shuffle(vipModels);
      const shuffledTopModels = shuffle(topModels);
      const shuffledVerifiedTrueModels = shuffle(verifiedTrueModels);
      const shuffledVerifiedFalseModels = shuffle(verifiedFalseModels);

      const finalModelsArray = [
        ...shuffledVipModels,
        ...shuffledTopModels,
        ...shuffledVerifiedTrueModels,
        ...shuffledVerifiedFalseModels,
      ];

      setHomeModels(finalModelsArray);
    },
    [setHomeModels],
  );

  const handleGetModels = useCallback(async () => {
    try {
      const response = await getModelsApi({});
      setShowModelsDisclaimer(false);
      handleSetHomeModels(response.models);
      checkRootPath();
    } catch (error) {
      console.error('Error fetching models:', error);
      // Manejo de errores adicional si es necesario
    }
  }, [getModelsApi, setShowModelsDisclaimer, handleSetHomeModels, checkRootPath]);

  const handleGetModelsByCity = useCallback(
    async (cityName: string) => {
      try {
        const response = await getModelsApi({ city: cityName });
        if (response.models.length > 0) {
          setShowModelsDisclaimer(false);
          handleSetHomeModels(response.models);
        } else {
          await handleGetModels();
          setShowModelsDisclaimer(true);
        }
        checkRootPath();
      } catch (error) {
        console.error('Error fetching models by city:', error);
        // Manejo de errores adicional si es necesario
      }
    },
    [
      getModelsApi,
      setShowModelsDisclaimer,
      handleSetHomeModels,
      handleGetModels,
      checkRootPath,
    ],
  );

  return {
    handleGetModels,
    handleGetModelsByCity,
    homeModels,
    isLoading,
    showModelsDisclaimer,
  };
};
