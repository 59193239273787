import { makeStyles } from '@mui/styles';
import { COLORS } from "@constants";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    overflow: "hidden",
    "@media (max-width: 780px)": {
      width: "45%",
    },
    "&:hover": {
      transform: 'scale(1.05)',
    },
  },
  bigCard: {
    position: "relative",
    overflow: "hidden",
    "@media (max-width: 780px)": {
      width: "100%",
    },
  },
  cardContainer: {
    height: "100%",
    //backgroundColor: COLORS.BG_Light,
    display: "block",
    textAlign: "center",
    position: "relative",
    background: "#fff",
    fontSize: "14px",
    color: "#2e2e2e",
    boxShadow: "0 2px 7px 0 rgba(0,0,0,.06)",
    cursor: "pointer",
    "&:hover": {
      borderWidth: 2,
      borderColor: COLORS.primary,
      borderStyle: "solid",
    },
  },
  cardImage: {
    position: "relative",
    width: "100%",
    aspectRatio: 0.6757,
  },
  tag: {
    position: "relative",
  },
  tagMembership: {
    "&::before": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      width: "auto",
      height: "auto",
      zIndex: 2,
      color: "white",
      fontWeight: 500,
      '@media (max-width: 960px)': {
        maxWidth: "45%",
      },
    }
  },
  tagVip: {
    "&::before": {
      background: COLORS.main,
      color: "white",
      content: '"VIP"',
      maxWidth: "30%",
      borderBottomRightRadius: "4px",
    },
  },
  tagTop: {
    "&::before": {
      background: "#ffd478",
      color: "black",
      content: '"TOP"',
      maxWidth: "30%",
      borderBottomRightRadius: "4px",
    },
  },
  tagFree: {
    "&::before": {
      background: "#575950",
      color: "white",
      content: '"FREE"',
      maxWidth: "100%",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
    },
  },
  tagProfileStatus: {
    "&::before": {
      position: "absolute",
      left: 0,
      right: 0,
      width: "auto",
      bottom: 0,
      height: "auto",
      zIndex: 2,
      borderRadius: "4px",
      color: "white",
      fontWeight: 500,
    }
  },
  tagVerified: {
    "&::before": {
      content: '"VERIFIED"',
      background: "#2bb222",
    },
  },
  tagNotVerified: {
    "&::before": {
      content: '"NOT VERIFIED"',
      background: "#7b7b7b",
      color: "#c30909",
      fontWeight: 800,
    },
  },
  cardTextContainer: {
    textAlign: "center",
    fontSize: "1.2rem",
    letterSpacing: ".1rem",
    lineHeight: "1.5rem",
    fontWeight: 500,
    border: "1px solid #ddd",
    padding: "5px",
    '& > h2' : {
      color: "black",
      fontSize: "1rem",
      margin: 0,
      height: "26px",
      lineHeight: "1.5rem",
      fontWeight: 300,
    },
    '@media (max-width: 960px)': {
      fontSize: "1rem",
      letterSpacing: ".1rem",
      lineHeight: "1rem",
      fontWeight: 300,
    },
  },
  lazzo: {
    position: "absolute",
    backgroundColor: "white",
    width: 300,
    textAlign: "center",
    top: "5%",
    right: "50%",
    padding: "3px 0px",
    transform: "rotate(-45deg)",
    "@media (max-width: 780px)": {
      top: "7%",
    },
  },
  smallLazzo: {
    position: "absolute",
    backgroundColor: "white",
    width: 300,
    textAlign: "center",
    top: "10%",
    right: "5%",
    padding: "3px 0px",
    transform: "rotate(-45deg)",
    "@media (max-width: 780px)": {
      top: "8%",
      right: "15%",
    },
  },
  starContainer: {
    position: "absolute",
    right: 0,
    bottom: 0,
    padding: 10,
    color: "white",
  },
}));

export { useStyles };
