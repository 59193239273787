import React from 'react'
import { Box } from '@mui/material';
import { useStyles } from "./styles";
import CountriesMenu from './Countries';
import { useGetModelsService } from '@hooks';

interface LeftPanelProps {
  onCloseMobileMenu?: () => void;
}

const LeftPanel: React.FC<LeftPanelProps> = ({onCloseMobileMenu}) => {
  const classes = useStyles();
  const { handleGetModels } = useGetModelsService();

  const handleGetModelsClick = async () => {
    await handleGetModels()
    if (onCloseMobileMenu) onCloseMobileMenu()
  }
  
  return (
    <Box className={classes.leftPanel}>
      <h1 className={classes.logo} onClick={handleGetModelsClick}>
        <img src="/img/logoHome.png" alt="Best Euro Girls" width="572" height="363" />
      </h1>
      <CountriesMenu/>
    </Box>
  );
};

export { LeftPanel };
