import { makeStyles } from '@mui/styles';
import { COLORS } from "@constants";

const useStyles = makeStyles(theme => ({
  headerBG: {
    backgroundImage: `url(/headerBG.png)`,
    backgroundSize: "cover",
    height: 200,
    textAlign: "center",
  },
  mainLogo: {
    width: 400,
    '@media (max-width: 960px)': {
      width: '50%',
    },
  },
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    /* position: "absolute", */
    /* height: 500, */
    /* backgroundColor: "rgba(0, 0, 0, 0.5)", */
    /* display: "flex", */
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  mobileInputContainer: {
    display: "none",
    /* "@media (max-width: 780px)": {
      backgroundColor: COLORS.BG_Light,
      position: "relative",
      display: "flex",
      justifyContent: "end",
      padding: 30,
      marginLeft: 20,
      borderRadius: 12,
      margin: "20px 0px",
    }, */
  },
  cutCirle: {
    backgroundColor: COLORS.BG_Dark,
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    left: 0,
    display: "flex",
    padding: 10,
    borderRadius: "50%",
  },
  headerMainText: {
    color: COLORS.primary,
    fontWeight: "bolder",
  },
  headerSecondaryText: {
    color: "white",
  },
  whiteText: { color: "white" },
  middlePanel: {
    marginBottom: 10,
    "@media (max-width: 780px)": {
      width: "100%",
      margin: "0px 5px",
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    gap: 10,
    justifyContent: "center",
    "@media (max-width: 780px)": {
      gap: 5,
    },
  },
}))

export { useStyles };
