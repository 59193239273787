import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles'
import { COLORS } from "@constants";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    fontFamily: "sans-serif",
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    gap: 30,
    padding: '1rem',
    backgroundColor: COLORS.tableBorder,
    textAlign: "center",
    "@media (max-width: 780px)": {
      gap: 40,
      padding: "10px 0px",
    },
  },
  tableFirstColumnHeader: {
    width: "40%",
    paddingLeft: "20px"
  },
  tableSecondColumnHeader: {
    width: "20%",
  },
  tableThirdColumnHeader: {
    textAlign: "center",
  },
  tableContentContainer: {
    display: "flex",
    flexDirection: "row",
    padding: '1rem',
  },
  tableFirstColumn: {
    paddingLeft: 20,
    //padding: "0px 20px",
    color: COLORS.tableText,
    fontWeight: 600,
    fontSize: ".8rem",
  },
  includeSection: {
    textAlign: "left",
    width: 10,
    paddingLeft: 0,
    fontSize: "1.5rem",
    '@media (max-width: 960px)': {
      fontSize: "1rem",
    },
  },
  tableSecondColumnTrue: {
    color: "#08D070",
  },
  tableSecondColumnFalse: {
    color: COLORS.primary,
  },
  tableThirdColumn: {
    textAlign: "center",
    fontWeight: 100,
    fontSize: ".8rem",
    '@media (max-width: 960px)': {
      textAlign: "right",
      paddingRight: 20,
    },
  },
  tableColumn: {
    padding: '1rem',
    textAlign: "left",
  },
  divider: {
    margin: '1rem',
  },
}));

export { useStyles };
